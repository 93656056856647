import React from "react"
import { Link } from "gatsby"

const Card = ({
    slug,
    title,
    body,
    author: {name},
    description: {description},
    heroImage: {
      file: {
        url
      }
    },
    tags,
    category,
    publishDate,
    ...props
  }) => {

  return (
    <div className = "xs:w-full sm:w-1/2 md:w-1/2 lg:w-1/3 h-200 min-h-200 p-4">
      <div className="bg-white rounded overflow-hidden shadow hover:shadow-lg border">
        <Link to={`/${slug}/`}>
          <img className="w-full h-35 object-cover" src={url} alt="" />
          <div className="px-6 py-2">
            <div className="font-bold text-base mb-2 text-left">{title}</div>
            <p className="text-gray-500 text-xs"><Link to={`/author/${name.toLowerCase()}/`}><span className="hover:underline">{name}</span></Link> | {publishDate}</p>
            <p className="text-gray-700 text-sm break-normal">{description}</p>
          </div>
          <div className="px-6 py-2">
            <Link to={`/category/${category.toLowerCase()}/`}>
              <div className="inline-block rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-4 border-gray-300 border-solid border hover:bg-tnl-blue">
                {category}
              </div>
            </Link>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Card;
