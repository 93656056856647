
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from "gatsby"
import React from "react"
import TnlLogo from "./tnlLogo"
import MobileMenu from "./mobileMenu"

const Header = ({ siteTitle, facebook, linkedin, instagram }) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost (filter: {node_locale: {eq: "en-US"}}){
        distinct(field: category)
      }
    }
  `)

  const categories = data.allContentfulBlogPost.distinct

  const catList = categories.map((item, key) =>
    <Link to={`/category/${item.toLowerCase()}/`}>
      <a className="pl-8 hover:font-semibold">{item}</a>
    </Link>
  )

  return (
    <nav className="xl:fixed lg:fixed bg-white w-screen border-gray-300 border-solid border py-2">
      <div className="hidden container mx-auto lg:flex justify-between items-center content-center px-4">
        <div className="flex items-end">
          <TnlLogo />
          <div className="text-l font-bold flex-grow-0 tracking-tight text-gray-800">{siteTitle}</div>
        </div>
        <div className = "flex justify-between items-center w-120 pr-4">
          {catList}
        </div>
      </div>
      <div className="flex lg:hidden container mx-auto px-4 justify-between items-center">
        <TnlLogo />
        <MobileMenu data={data} facebook={facebook} linkedin={linkedin} instagram={instagram}/>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
