import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, categories }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          facebook
          instagram
          linkedin
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        facebook={data.site.siteMetadata.facebook}
        instagram={data.site.siteMetadata.instagram}
        linkedin={data.site.siteMetadata.linkedin}
      />
      <div className = "bg-gray-100 min-h-screen">
        <div className = "container mx-auto py-3">
          <main>
            {children}
          </main>
        </div>
      </div>
      <footer>
        <Footer
          facebook={data.site.siteMetadata.facebook}
          instagram={data.site.siteMetadata.instagram}
          linkedin={data.site.siteMetadata.linkedin}
        />
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
