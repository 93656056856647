import React from "react"

class NewsletterSignUp extends React.Component {

  render () {
    return (
      <div className="container flex justify-center">
        <form name="Newsletter Sign up" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/NewsletterSubscribed">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="newsletter Sign up" />
          <input className="rounded border-gray-300 border-solid border py-2 px-4" type="email" name="emailAddress" placeholder="you@domain.com"/>
          <button type="submit" className="rounded px-3 py-2 mx-4 bg-black text-white hover:bg-gray-500">Sign up</button>
        </form>
      </div>
    )
  }
}

export default NewsletterSignUp
