import React from 'react'

import TnlLogo from "./tnlLogo"
import TnlFacebook from "./tnlFacebook"
import TnlLinkedIn from "./tnlLinkedIn"
import TnlIG from "./tnlIG"
import NewsletterSignUp from "./newsletterSignUp"

const Footer = ({ siteTitle, facebook, instagram, linkedin}) => {
  return (
    <>
    <div className="bg-tnl-green w-screen py-12 overflow-x-hidden hidden lg:block">
      <div className="container mx-auto">
        <div className="container flex justify-between">
          <div className="container flex">
            <div className="container mx-4">
              <h3 className="">About Us</h3>
              <p className="text-xs">We believe in changing the status quo in schools and that offering freshly cooked, qualitative and affordable food to children at school every day is possible. We want The New Luncher to become the second kitchen of every home, a place where we prepare the same meals parents cook for dinner and on weekends, with as much implication and love.</p>
            </div>
          </div>
          <div className="container mx-4">
            <h3 className="">Newsletter</h3>
            <p className="text-left leading-tight text-xs">Keep up to date with The New Luncher’s marketing team — sign up for our newsletter and stay informed.</p>
            <div className="flex mb-8">
              <NewsletterSignUp />
            </div>
          </div>
          <div className="container mx-4">
            <h3 className="">Visit us</h3>
            <a className="block" href="https://www.thenewluncher.com" target="_blank" rel="noopener noreferrer">The New Luncher - Singapore</a>
            <a className="block" href="https://thenewluncher.hk" target="_blank" rel="noopener noreferrer">The New Luncher - Hong Kong</a>
            <div className = "flex justify-between items-center w-24 mt-6 pr-4">
              <TnlFacebook link={facebook} size={"20"}/>
              <TnlIG link={instagram} size={"20"}/>
              <TnlLinkedIn link={linkedin} size={"20"}/>
            </div>
          </div>
        </div>
        <p className="mx-4 text-xs">&copy; The New Luncher Pte. Ltd</p>
      </div>
    </div>
    <div className="bg-tnl-green w-screen py-4 overflow-x-hidden block lg:hidden container flex justify-center">
      <TnlLogo />
    </div>
    </>
  )
}

export default Footer
