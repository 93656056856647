import React , { Component } from "react"
import { Link } from "gatsby"
import TnlLogo from "./tnlLogo"
import TnlFacebook from "./tnlFacebook"
import TnlLinkedIn from "./tnlLinkedIn"
import TnlIG from "./tnlIG"

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick = (e) => {
    e.preventDefault()
    this.setState({
      menuOpen: true
    })
  }

  handleClose() {
    this.setState({
      menuOpen: false
    })
  }

  render() {

    const catList=this.props.data.allContentfulBlogPost.distinct.map((item, key) => {
      return (
        <Link to={`/category/${item.toLowerCase()}/`}>
          <a className="px-4 hover:underline">{item}</a>
        </Link>
      )
    })

    return (
      <div>
        <div className="inline-block">
          <button className="flex items-center px-3 py-2 rounded text-black hover:text-black" onClick={this.handleClick}>
            <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        {
          this.state.menuOpen ?
            <div className="w-screen h-screen fixed z-50 top-0 left-0 bg-white overflow-hidden">
              <div className ="absolute top-0 right-0 m-8">
                <button className="flex item-center rounded" onClick={this.handleClose}>
                  <svg className="fill-current h-5 w-5" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="20"
                              x2="20" y2="1"
                              stroke="black"
                              stroke-width="2"/>
                    <line x1="1" y1="1"
                          x2="20" y2="20"
                          stroke="black"
                          stroke-width="2"/>
                  </svg>
                </button>
              </div>
              <div className="container m-auto py-8">
                <div className = "flex justify-center">
                  <TnlLogo />
                </div>
                <div className="container m-auto py-8">
                  <h3 className="text-center">Category</h3>
                  <div className ="flex justify-center -px-4">
                    {catList}
                  </div>
                </div>
                <div className = "container">
                  <h3 className="text-center">Visit us</h3>
                  <a className="block text-center" href="https://www.thenewluncher.com" target="_blank" rel="noopener noreferrer">The New Luncher - Singapore</a>
                  <a className="block text-center" href="https://thenewluncher.hk" target="_blank" rel="noopener noreferrer">The New Luncher - Hong Kong</a>
                  <div className = "mx-auto flex justify-between items-center w-24 mt-6 pr-4">
                    <TnlFacebook link={this.props.facebook} size={"20"}/>
                    <TnlIG link={this.props.instagram} size={"20"}/>
                    <TnlLinkedIn link={this.props.linkedin} size={"20"}/>
                  </div>
                </div>
              </div>
            </div>
          :
            null
        }
      </div>
    )
  }

}

export default MobileMenu;
